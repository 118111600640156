import React from 'react'
import { ContactPageTemplate } from 'precision/pages/contact' //TODO:create index, webpack alias, etc to clean up. also rename components to lib and precision to components

const ContactPage = () => (
  <>
    <ContactPageTemplate />
  </>
)

export default ContactPage
