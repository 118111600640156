import React from 'react'
import { Form, asField } from 'informed'

import { Flex, Text, Icon, Label, Input, Textarea, Spacer } from 'components'
import { navigate } from 'gatsby' //prob move outside of here?

const handleSubmit = data => {
  if (!data || data.social) {
    return
  }
  fetch('/.netlify/functions/sendEmail', {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(response => {
    //TODO: check and handle error
    navigate('/success')
  })
}

const FormInput = asField(({ fieldState, fieldApi, ...props }) => {
  const { value } = fieldState
  const { setValue, setTouched } = fieldApi
  const { onChange, onBlur, initialValue, forwardedRef, ...rest } = props
  return (
    <Input
      {...rest}
      ref={forwardedRef}
      value={!value && value !== 0 ? '' : value}
      onChange={e => {
        setValue(e.target.value)
        if (onChange) {
          onChange(e)
        }
      }}
      onBlur={e => {
        setTouched(true)
        if (onBlur) {
          onBlur(e)
        }
      }}
    />
  )
})

const FormInputArea = asField(({ fieldState, fieldApi, ...props }) => {
  const { value } = fieldState
  const { setValue, setTouched } = fieldApi
  const { onChange, onBlur, initialValue, forwardedRef, ...rest } = props
  return (
    <Textarea
      {...rest}
      ref={forwardedRef}
      value={!value && value !== 0 ? '' : value}
      onChange={e => {
        setValue(e.target.value)
        if (onChange) {
          onChange(e)
        }
      }}
      onBlur={e => {
        setTouched(true)
        if (onBlur) {
          onBlur(e)
        }
      }}
    />
  )
})

const ContactForm = () => (
  <Flex
    onSubmit={handleSubmit}
    name="contact"
    as={Form}
    col
    p={16}
    width={['100%', '50%']}
    grow
  >
    <FormInput type="hidden" name="social" field="social" isHoneypot />

    <Label htmlFor="name">Name</Label>
    <FormInput field="name" required />
    <Label>Phone</Label>
    <FormInput field="phone" required type="phone" />
    <Label>Email</Label>
    <FormInput field="email" required type="email" />
    <Label>Message</Label>
    <FormInputArea field="message" />
    <Spacer />

    {/* <button type="submit">Submit</button> */}
    <Flex
      as="button"
      type="submit"
      bg="brand.primary"
      p={16}
      hAlignContent="center"
      vAlignContent="center"
    >
      <Text
        color="white"
        textTransform="uppercase"
        fontWeight="bold"
        fontSize="lg"
      >
        Send Now
      </Text>
    </Flex>
  </Flex>
)
export default ContactForm
