import React from 'react'
import { Link } from 'gatsby'
import {
  Flex,
  Heading,
  Text,
  Icon,
  OutlineButton,
  HorizontalRule,
} from 'components'
import { ContactForm } from './'

///TODO:autoslide pipe pictures under
//TODO:create a container which is just a cenetered Flex
//contact_sales
//puddling issues, water seeping into the foundation of your house? wehandle all these issues and more, give us a call for more information

//TODO: stateless using hooks?

const ContactPageTemplate = ({}) => (
  <>
    <Flex bg="brand.primary" p={16} hAlignContent="center" col color="white">
      <Heading.h1>Let's Get In Touch</Heading.h1>
      <HorizontalRule />

      <Heading.h3>What can we do to help?</Heading.h3>
    </Flex>
    <Flex wrap bg="offWhite" grow>
      <Flex col color="white" width={['100%', '50%']}>
        <Flex
          bg="brand.primary"
          col
          hAlignContent="center"
          vAlignContent="center"
          grow
          m={16}
        >
          <Icon name="phone" size={100} />
          <Heading.h1>Give us a call</Heading.h1>
          <HorizontalRule />
          <Text>For general inquiries and information, call us at:</Text>

          <Heading.h1>770-594-8135</Heading.h1>
        </Flex>
        <Flex
          bg="brand.secondary"
          m={16}
          p={16}
          hAlignContent="center"
          vAlignContent="center"
          as={Link}
        >
          <OutlineButton variant="primary">
            Request a free estimate
          </OutlineButton>
        </Flex>
      </Flex>
      <ContactForm />
    </Flex>
  </>
)

export default ContactPageTemplate
